//
// Pages
//
// Styles specific to individual pages.
//

@import 'pocetna';
@import 'oanandi';
@import 'program';
@import 'raspored';
@import 'cjenik';
@import 'novosti';
@import 'kontakt';
@import 'newsletter';
@import 'racun'; 

// Styles for all pages
//

main {
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  ul {
    margin-right: 18px;
    margin-left: -18px;
    list-style-type: disc;
  }

  article {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    text-align: justify;
  }

  article p {
    hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    margin-bottom: 0;
  }

  article ul {
    padding-left: 40px;
    list-style-position: inside;
    // list-style-image: url('../images/usluge/yinyang.png');
  }

  article ul li {
    text-align: left;
    list-style-type: disc;
    list-style-position: inside;
    text-indent: -1.2em;
    padding-left: 1em;
    font-size: 1.1em;
    // font-style: italic;
    line-height: 1.4;
    margin-bottom: 16px;
  }

  article ul li:before {
    // content: '\1F95E'; 
    // margin-left: -20px; 
    // margin-right: 10px; 

    .gg-yinyang;

  } 

  article p ~ div {
    margin-top: 40px;
  }

  article h1 ~ p,
  article h2 ~ p,
  article h3 ~ p,
  article h4 ~ p {
    margin-top: 0;
  }

  article section {
    margin-top: ((@margin-image-thumbnail * 2) / 1.25);
  }

  article h1 + section,
  article h2 + section,
  article h3 + section {
    margin-top: 0;
  }

  .content-bg-large {
    position: static;
    z-index: 0;
    //margin-top: -(@size-header + (@size-header / 2));
    //margin-top: -(@size-header);
    //padding-bottom: 100px;
  }

  .content-bg-large > section {
    flex-wrap: wrap;
    justify-content: center;
  }

  article section:last-child {
    margin-bottom: 0;
  }

  // section ~ .hor-rul {
  //   margin-top: -(@margin-top-h3) + (@size-header / 2) + (@margin-top-h2 / 2); 
  // }
}

p a {
  color: @color-links;
  box-shadow: inset 0 -2px 0 0 lighten(@color-links, 40%), inset 0 -3px 0 0 lighten(@color-links, 40%);
}

h1 {
  .h1();
  //margin-top: @margin-top-h2;
  margin-bottom: 140px;
  margin-top: 160px;
  font-weight: 400;
  text-align: center;
}

h2 {
  .h1();

  margin-top: @margin-top-h2;
  margin-bottom: @margin-bottom-h2;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

h3 {
  margin-top: @margin-top-h3;
  margin-bottom: @margin-bottom-h3;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

h6 {
  margin: 0;
  padding: 0;
  margin-top: @margin-top-h3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @font-family-headings;
  hyphens: none;
  color: @color-headings;
}

a:focus,
a:hover {
  color: @color-links_hover;
  text-decoration: none;
}

table {
  font-size: 0.875em;
  color: @color-paragraph;
  letter-spacing: -0.008em;
}

b {
  font-weight: 600;
}

blockquote {
  border: 0;
}

hr {
  border-top: 1px solid @color-headings;
}

.hr {
  display: block;
  height: 0;
  margin-top: (@margin-top-h3);
  //margin-bottom: 12px;
}

.hr::after,
.hr::before {
  position: absolute;
  left: 10px;
  display: block;
  width: 40%;
  height: 1px;
  content: ' ';
  border-top: 1px solid #e2c6e4;
}

.hr::after {
  right: 10px;
  left: auto;
}

.hr_img {
  position: absolute;
  left: 50%;
  width: 44px;
  height: 44px;
  margin-right: -50%;
  //top: 50%;
  background: url('../images/ohm.png') 0 0 no-repeat;
  transform: translate(-50%, -22px);
}

.h1() {
  margin-bottom: @padding-master_top;
  text-align: center;
  //&:extend(h2);
}

.reset-p {
  margin: 0;
  text-indent: 0;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

// ReCaptcha
.g-recaptcha {
  display: inline-block;
}

// Thumbnails
.img-thumbnail {
  padding: 3px;
  margin-bottom: 30px;
  border-radius: 4px;
}

// Breakout
.breakout {
  position: absolute;
  right: 50%;
  left: 50%;
  width: 100vw;
  margin-right: -50vw;
  margin-left: -50vw;
}

// Google reCaptcha
.grecaptcha-badge { 
    visibility: hidden; 
}

.branding-google-recaptcha {
  margin-top: 30px;

  p {
    font-size: 0.5em;
  }

  a {
  -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
  }
}

// Flash messages
body > p.flash-message {
  width: 500px;
  left: 50%;
  top: 100px;
  margin-left: -250px;
  color: #fff;
  font-size: 14px;
  padding: 8px 30px 8px 15px;
  z-index: 10300;
  word-wrap: break-word;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.15);
  text-align: center;
  -webkit-box-shadow: 0 1px 6px rgba(0,0,0,0.12),0 1px 4px rgba(0,0,0,0.24);
  box-shadow: 0 1px 6px rgba(0,0,0,0.12),0 1px 4px rgba(0,0,0,0.24);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

//Breakpoints listener
#breakpoint.position {
  position: fixed;
  z-index: 1000;
  padding-right: 10px;
  padding-left: 10px;
  background: red;
  opacity: 0.8;
}

// Buttons
.btn-group-lg > .btn, .btn-lg {
  padding: 10px 16px;
  font-size: @btn-font-size;
  line-height: 1.3333333;
  border-radius: 6px;
}

.setPageSizes(@size) {
  article p {
    font-size: (@font-size-base * @size);
  }

  h2 {
    font-size: (@font-size-h2 * @size);
    margin-top: (@margin-top-h2 * @size);
    margin-bottom: (@margin-bottom-h2 * @size);
  }

  h3 {
    font-size: (@font-size-h3 * @size);
    margin-bottom: (@margin-bottom-h2 * @size);
  }

  .hr {
    margin-top: (@size-header);
  }

  // section ~ .hor-rul {
  //   margin-top: (((@size-header + (@margin-top-h2 * @size)) / 2) - (@margin-top-h3 * @size) - ((@line-height * (@font-size-base * @size)) / 2));
  // }
}