//
// Pages
//
// Novosti styles
//

ul.post-list,
ul.category-list {
	list-style-type: none;
	padding:0; 
	margin:0;
}

ul.category-list {
	text-align: right;
	font-size: 0.8em;
	position: fixed;
}

ul.post-list h3 {
	font-size: 1.6em;
	margin-bottom: 0;
}

ul.post-list img {
	padding: 6px 6px 0 0;
}

p.info {
	&:extend(small);
	margin-bottom: 6px;
}

ul p + p {
	text-indent: 0;
	margin-top: -1.5rem;
}

.pagination > li > a, .pagination > li > span {
	border: 1px solid #dcb0dd;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	background-color: @color-nb_bg;
	border-color: #dcb0dd;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
	background-color: lighten(@color-nb_bg, 71%); 
	border-color: #dcb0dd;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
	color: darken(@color-nb_bg, 10%);
}

.pagination > li > a, .pagination > li > span {
	color: @color-nb_bg;
}

.pagination > li > a > span {
	display: block;
	position: relative;
	top: -2px;
}