//
// Cjenik media queries
//
//
/* Large devices (large desktops, 1200px and up) */
//
@media (min-width: @screen-lg-min) {
  .cjenik {
    table {
      font-size: 1em;
    }

    small {
      font-size: 1em;
    }

    .table {
      & > tbody > tr > th {
        background-color: @color-theader;
        color: @color-theader_text;
        font-family: @font-family-headings;
        font-size: 1.5em;
        font-weight: 400;
        letter-spacing: 0.008em;
        vertical-align: middle;
      }
    }
  }
}
