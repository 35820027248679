//
// Pocetna media queries
//
//

/* Medium devices (desktops, 992px and up) */
//
@media (min-width: @screen-md-min) {
  // Nav
  #large-anchor-nav .anchor-buttons {
    h1 {
      margin-top: 0.5em;
      margin-bottom: 0.15em;
      font-size: (@font-size-h2 / 1.3);
    }

    p {
      width: 90%;
      font-size: 0.85em;
    }

    & > div {
      height: 100%;
    }

    a:hover {
      .btn-circle {
        //transform: scale(1.1);
        background-color: lighten(@color-round_button, 2%);
        .box-shadow(@shadow: 0 0 1px 8px rgba(0, 0, 0, 0.1));
        .transition(box-shadow);

        transition: box-shadow 0.2s;
      }
    }
    .set-button(@radius-round_button-md, @iconsize-round_button-md, @iconpadding-round_button-md);
  }
}
