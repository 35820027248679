//
// Raspored media queries
//
//

/* Small devices (tablets, 768px and up) */
//

@media (min-width: @screen-sm-min) {
  .wrapper {
    height: calc(~'100vh'- (@size-footer-sm + @size-header));
  }
}