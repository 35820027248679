.racun 
{
	.content .row {
		//margin-left: 10%;
		//margin-right: 10%;
	} 

    .content {
        padding-top: (@size-header/2);
		padding-bottom: (@size-header/2);
	}

    & .form-control {
        border: 1px solid #e7d1e7;
        background: rgb(255, 255, 255);
        /* fallback color */
        background: rgba(255, 255, 255, 0.5);
		font-size: 1em; 
        &:focus {
            border-color: #e7d1e7;
            outline: 0;
            .box-shadow(inset 0 0 1px fade(@color-nb_bg, 0%), 0 0 3px fade(@color-nb_bg, 20%);
            );
        }
    }

    .panel-body .form-group:last-child {
        padding-bottom: 20px;
    } 

    .create-account {
        .pull-right();
        display: inline-block;
        font-size: 0.8em;
        margin: 0;
        margin-top: -20px;
        margin-bottom: -10px;
        position: relative;
        // top: 100%;
        // -ms-transform: translateY(100%);
        // transform: translateY(100%);
    }
}

