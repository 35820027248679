//
// All pages media queries
//
//

/* XXtra large devices (very large desktops, 1920px and up) */
//

@size-xxlg: 1.3;

@media (min-width: @screen-xxlg) {
  body::before {
    content: 'XXLarge devices';
  }

  .setPageSizes(@size-xxlg);
}
