// Footer
/////////////////////////////

// Footer variables
@color-footer_bg: #11151c;
@color-footer_headers: #777777;
@color-footer_desc: #6F6F6F;
@color-footer_links: #bbbbbb;
@font-size-footer: 0.8em;
@font-size-footer_headers: 1.6em;
@font-size-footer_desc: 0.9em;
@font-size-footer_lists: 0.6em;

.footer {
  position: absolute;
  bottom: auto;
  width: 100%;
  //padding-top: 20px;
  font-family: @font-family_footer;
  font-size: @font-size-footer;
  background-color: @color-footer_bg;
  .opacity(0.93);

  ul {
    display: block;
    height: 100%;
    padding: 0;
    margin: 0;

    li {
      //float: none;
      display: block;
      //font-size: @font-size-footer_lists;
      line-height: 2.3;
      color: @color-footer_links;
      list-style-position: inside;
      list-style-type: none;

      i,
      span {
        position: relative;
        top: 0.25em;
        left: -5px;
        display: block;
        float: left;
        width: 30px;
        font-size: 1.5em;
        text-align: center;
      }

      a {
        color: @color-footer_headers;
        text-decoration: none;
        cursor: auto;

        &:hover {
          color: @color-footer_links;
          cursor: pointer;
        }
      }

      h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-family: @font-family-headings;
        font-size: @font-size-footer_headers;
        color: @color-footer_links;
        text-align: left;
        cursor: default;
      }

      p {
        line-height: 1.4;
      }

      h6 + p {
        position: relative;
        top: -16px;
        margin: 0;
        font-size: 0.65rem;
        font-weight: normal;
        color: lighten(@color-footer_bg, 50%);
      }

      h1 + p {
        margin-top: 1px;
        margin-bottom: 10px;
        font-family: @font-family_footer;
        font-size: @font-size-footer_desc;
        color: @color-footer_desc;
        cursor: default;
      }

      img {
        margin-bottom: -45px;
      }
    }
  }

  button,
  input {
    position: relative;
    box-sizing: content-box;
    display: inline-block;
    //padding: 0 0 0em 0.2em;
    margin: 0;
    //top: -10px;
    //height: 1.8em;
    font-size: 0.8em;
    vertical-align: middle;

    /* the following ensures they're all using the same box-model for rendering */
    -moz-box-sizing: content-box;

    /* or `border-box` */
    -webkit-box-sizing: content-box;
  }

  button {
    margin-top: 1em;
    padding: 0.7em 1.5em;
    color: #181a1c;
    background-color: #ddd;
    border: 0;
    border-radius: 6px;
    .pull-right;

    &:hover {
      background: lighten(lightgray, 35%);
    }
  }

  input {
    width: 100%;
    //padding: 2px;
    margin-bottom: 10px;
    font-size: 1em;
    color: lighten(desaturate(#a1a, 60%), 20%);
    text-indent: 10px;
    background: darken(@color-footer_bg, 5%);
    border: 0;
    border-right: 0;
    border-bottom: 4px solid lighten(@color-footer_bg, 5%);

    & .fname,
    & .sname {
      width: 100%;
      margin-bottom: 20px;
      .pull-left;
    }

    & .sname {
      margin-right: -3px;
      .pull-right;
    }

    & .address {
      width: 100%;
      margin-top: 5%;
    }

    &::placeholder {
      color: darken(desaturate(lighten(#a1a, 80%), 60%), 70%);
    }

    &:focus {
      border-bottom: 4px solid lighten(@color-footer_bg, 15%);
    }
  }

  label.error {
    display: block;
    width: 100%;
    margin-top: -10px;
    font-size: 0.8em;
    font-weight: normal;
    color: lighten(desaturate(red, 20), 10%);
    text-align: right;
    text-indent: 10px;
  }

  h6 {
    display: block;
    padding: 0;
    margin-left: 0;
    font-family: @font-family_footer;
    font-size: 0.8em;
    font-weight: normal;
    line-height: 2.2;
    color: lighten(@color-links, 35%);
  }

  .fa.pull-left {
    margin-right: 0;
  }

  .g-recaptcha {
    float: right;
    padding-left: 10px;
    clear: both;
  }

  .copyright {
    position: relative;
    height: 80px;
    //margin-top: 20px;
    background-color: black;
  }

  .row > ul > li {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
    float: none;
  }
}
