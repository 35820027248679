//
// All pages media queries
//
//

/* Small devices (tablets, 768px and up) */
//

@size-sm: 1.1;

@media (min-width: @screen-sm-min) {
  body::before {
    content: 'Small devicess';
  }

  .setPageSizes(@size-sm);
}
