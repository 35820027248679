//
// All pages media queries
//
//

/* Extra large device (very large desktops, 1600px and up) */
//

@size-xlg: 1.18;

@media (min-width: @screen-xlg) {
  body::before {
    content: 'XLarge devices';
  }

  .setPageSizes(@size-xlg);

  .content-bg-small::before {
    position: relative;
  }

  .content-bg-large::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    color: @rgba;
    content: '';
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 2px 0 @rgba;
    opacity: 0.9;
    -moz-box-shadow: 0 0 2px 0 @rgba;
    -webkit-box-shadow: 0 0 2px 0 @rgba;
    .hex-to-rgba (#979797, 0.5);
  }

  .content-bg-large {
    position: relative;
    z-index: 0;
  }

  .centered-layout .content-bg-large {
    position: relative;
    z-index: 0;
  }
}
