//
// All pages media queries
//
//
/* Medium devices (desktops, 992px and up) */
//

@media (min-width: @screen-md-min)
{

}

@media (min-width: @screen-md-min) and (min-height: 1180px) {
    .racun .content {
        margin-top: 0;
    }
}