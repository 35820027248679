/* playfair-display-regular - latin_latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

// /* playfair-display-700 - latin_latin-ext */
// @font-face {
//   font-family: 'Playfair Display';
//   font-style: normal;
//   font-weight: 700;
//   src: url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
// }

// /* playfair-display-900 - latin_latin-ext */
// @font-face {
//   font-family: 'Playfair Display';
//   font-style: normal;
//   font-weight: 900;
//   src: url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
// }

// /* playfair-display-italic - latin_latin-ext */
// @font-face {
//   font-family: 'Playfair Display';
//   font-style: italic;
//   font-weight: 400;
//   src: url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
// }

// /* playfair-display-700italic - latin_latin-ext */
// @font-face {
//   font-family: 'Playfair Display';
//   font-style: italic;
//   font-weight: 700;
//   src: url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700italic.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-700italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
// }

// /* playfair-display-900italic - latin_latin-ext */
// @font-face {
//   font-family: 'Playfair Display';
//   font-style: italic;
//   font-weight: 900;
//   src: url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900italic.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900italic.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/PlayfairDisplay/playfair-display-v20-latin_latin-ext-900italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
// }