/* pt-sans-narrow-regular - latin */
@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('PT Sans Narrow'), local('PTSans-Narrow'),
       url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-regular.svg#PTSansNarrow') format('svg'); /* Legacy iOS */
}

// /* pt-sans-narrow-700 - latin */
// @font-face {
//   font-family: 'PT Sans Narrow';
//   font-style: normal;
//   font-weight: 700;
//   src: url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-700.eot'); /* IE9 Compat Modes */
//   src: local('PT Sans Narrow Bold'), local('PTSans-NarrowBold'),
//        url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-700.woff') format('woff'), /* Modern Browsers */
//        url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('../fonts/PTSansNarrow/pt-sans-narrow-v11-latin-700.svg#PTSansNarrow') format('svg'); /* Legacy iOS */
// }