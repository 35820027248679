//
// Pages
//
// O Anandi
//

.oanandi {
  position: relative;
  padding-bottom: 50px;

  .container:first-of-type {
    margin-top: -450px;
  }

  h1 > span {
    display: block;
    clear: left;
    margin-top: 10px;
    font-size: 2.5rem;
    &:extend(h5);
  }
  
  h1 {
    font-size: 4em;
    margin-bottom: 200px;
  }

  .sideimgs {
    margin-top: 100px;
  }

  .sideimgs img {
    padding-bottom: 20px;
  }

  .heading {
    img {
      .img-responsive;

      width: 100%;
    }
  }

  .content {
    margin-top: 50px;
  }
}
