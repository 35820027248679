//
// All pages media queries
//
//
/* Large devices (large desktops, 1200px and up) */
//

@media (min-width: @screen-lg-min)
{

}
