//
// Layouts
//
// Styles specific to different page layouts.
//
@import 'centered-layout';
@import 'overflow-layout';

html {
  position: relative;
}

body {
  overflow-x: hidden;
  font-style: normal;
  font-weight: 400;
  line-height: @line-height;
  background: url('../images/textures/spirals.png') repeat;
  background-color: @color-bg;
}

body::before {
  display: none;
  visibility: hidden;
  content: 'XSmall devices';
}

main {
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@import 'maintenance-layout';
