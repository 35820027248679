/* Generated by Glyphter (http://www.glyphter.com) on  Fri Mar 11 2016*/
@font-face {
    font-family: 'Glyphter';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Glyphter/Glyphter.eot');
    src: url('../fonts/Glyphter/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter/Glyphter.svg#Glyphter') format('svg');
}

[class^='icon-']:before {
	display: inline-block;
	font-family: 'Glyphter';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
}

.set-icon(@icon-size, @padding-top) {

	.btn-group-lg > .btn, .btn-lg {
		padding-top: @padding-top;
	}

	.icon-1:before {
	padding-top: 9px;
	font-size: @icon-size;
	color: #ffffff;
	content: '\0041';
	}

	.icon-2:before {
	//	padding-left: 1px;
		padding-top: 8px;
		font-size: @icon-size;
		color: #ffffff;
		content: '\0042';
	}

	.icon-3:before {
		//padding-left: 1px;
		padding-top: 10px;
		font-size: @icon-size;
		color: #ffffff;
		content: '\0043';
	}

	.icon-4:before {
		//padding-left: 1px;
		padding-top: 10px;
		font-size: 25pt;
		color: #000000;
		content: '\0044';
	}
}

.set-icon(@iconsize-round_button, @iconpadding-round_button);
