//
// All pages media queries
//
//
/* Medium devices (desktops, 992px and up) */
//

@media (min-width: @screen-md-min)
{
    .kontakt {	
        .content {
            margin-top: 0;
        }
    }
}
