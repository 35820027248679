//
// All pages media queries
//
//

/* Medium devices (desktops, 992px and up) */
//

@size-md: 1.15;

@media (min-width: @screen-md-min) {
  body::before {
    content: 'Medium devices';
  }

  .setPageSizes(@size-md);

  .footer .row > ul > li {
    float: left;
    margin: none;
  }
}
