//
// Pages
//
// Newsletter
//


.newsletter {

	.data {
		padding-top: 10px;
		background-color: #fff3fd;
	}
	
	p+p {
		padding-top: 10px;
		text-indent: 0;
	}
	
	.data.panel p {
		padding-top: 0;
	}
	
	.data.panel b {
		color: lighten(desaturate(red, 30%), 10%);
	}
	
	.g-recaptcha {
		padding-bottom: 26px;
	}
	
	button {
		margin-bottom: 22px
	}
	.content-bg-large {
		margin-top: 0;
}
}