//
// Centered layout
//

.content_center {
  .wrapper {
    position: relative;
    display: table;
    height: calc(~'100vh' - (@size-footer + @size-header));
    //padding: 60px 0;
    margin: 0;
    width: 100%;
  }

  .middle {
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }
}

.centered-layout .content-bg-small {
  position: static;
  z-index: 1;
}

.centered-layout .content-bg-large {
  position: relative;
  z-index: 0;
}
