//
// Pocetna media queries
//
//

/* Extra large device (very large desktops, 1600px and up) */
//
@media (min-width: @screen-xlg) {
  // Nav
  #large-anchor-nav .anchor-buttons {
    h1 {
      margin-top: 0.3em;
      margin-bottom: 0.15em;
      font-size: (@font-size-h2);
    }

    p {
      width: 70%;
      font-size: 1em;
    }

    & > div {
      height: 100%;
    }
    .set-button(@radius-round_button-lg, @iconsize-round_button-lg, @iconpadding-round_button-lg);
  // Nav end
  }
}
