//
// Pocetna media queries
//
//

/* Small devices (tablets, 768px and up) */
//

@media (min-width: @screen-sm-min) {
  // Nav
  #large-anchor-nav .anchor-buttons {
    & > div {
      height: 22vh;
    }

    h1 {
      margin-top: 0.15em;
      margin-bottom: 0;
      font-size: 1.5em;
    }

    p {
      width: 60%;
      font-size: 0.8em;
    }

  .set-button(@radius-round_button-sm, @iconsize-round_button-sm, @iconpadding-round_button-sm);
  }
  // Nav end
}
