//
// All pages media queries
//
//

/* Medium devices (desktops, 992px and up) */
//

@media (min-width: @screen-md-min) {
  .wrapper {
    height: calc(~'100vh' - (@size-footer-md + @size-header));
    .middle {
      @height-raspored-middle: ((@height-raspored-box + @margin-bottom-raspored-box) * 2);
      @height-raspored-wrapper: calc(~'100vh' - (@size-footer-md + @size-header));
      padding-top: calc(((@height-raspored-wrapper - @height-raspored-middle) / 2));
      margin-top: @margin-bottom-raspored-box;
    }
  }
}

@media (min-height: 1180px) and (min-width: @screen-md-min) {
  .content_center;

  .wrapper {
    height: calc(~'100vh' - (@size-footer-md + @size-header));
    .middle {
      padding-top: 0;
    }
  }

  body.centered-layout {
    height: 100%;
    margin-bottom: 0;
  }

  html.centered-layout {
    height: 100%;
  }

  .footer.centered-layout {
    bottom: 0;
  }

  .raspored .content {
    padding-top: 25px;
  }
}
