//
// Pocetna media queries
//
//

/* Large devices (large desktops, 1200px and up) */
//

@media (min-width: @screen-lg-min) {
  // Nav
  #large-anchor-nav .anchor-buttons {
    h1 {
      margin-top: 0.5em;
      margin-bottom: 0.15em;
      font-size: (@font-size-h2 / 1.2);
    }

    p {
      width: 85%;
      font-size: 0.9em;
    }

    & > div {
      height: 100%;
    }
  }
}
