//
// All pages media queries
//
//
/* Extra large device (very large desktops, 1600px and up) */
//

@media (min-width: @screen-xlg)
{
    .wrapper {
        height: (1180 - (@size-footer-md + @size-header));
        .middle {
          @height-raspored-middle: ((@height-raspored-box + @margin-bottom-raspored-box) * 2);
          @height-raspored-wrapper: calc(~'100vh' - (@size-footer-lg + @size-header));
          //padding-top: ((1180 - (@size-footer-lg + @size-header)) - ((@height-raspored-box + @margin-bottom-raspored-box) * 2) - @margin-bottom-raspored-box));
          padding-top: (((1180 - (@size-footer-lg + @size-header)) - (((@height-raspored-box + @margin-bottom-raspored-box) * 2) - @margin-bottom-raspored-box)) / 2);
          //margin-top: @margin-bottom-raspored-box;
          //padding-top: 0;
          margin-top: 0;
        }
      }
}

@media (min-height: 1180px) and (min-width: @screen-xlg) {
    .content_center;
  
    .wrapper {
      height: calc(~'100vh' - (@size-footer-lg + @size-header));
        .middle {
            padding-top: 0;
            margin-top: 0;
        }
    }
  
    body.centered-layout {
      height: 100%;
      margin-bottom: 0;
    }
  
    html.centered-layout {
      height: 100%;
    }
  
    .footer.centered-layout {
      bottom: 0;
    }
  
    .raspored .content {
         margin-top: 0;
    }
    
  }
