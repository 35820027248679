//
// Pages
//
// Program
//

.program {
  position: relative;
  padding-bottom: 100px;
  
  .wrapper {
    padding: 0;
  }

  h4 {
    hyphens: none;
    text-align: left;
  }

  h6 {
    position: relative;
    margin-bottom: -10px;
    font-family: @font-family_footer;
    font-size: 0.8em;
    color: desaturate(lighten(@color-headings, 20), 20);
  }
}
