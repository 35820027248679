// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @media (min-width: @screen-xlg) {
    width: @container-xlg;
  }
}

// Extra Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-xlg) {
  .make-grid(xlg);
}
