//
// Pages
//
// Kontakt styles
//

.kontakt {
    .content {
		margin-top: (@size-header/2);
		margin-bottom: (@size-header/2);
	} 
	
    & .form-control {
        border: 1px solid #e7d1e7;
        background: rgb(255, 255, 255);
        /* fallback color */
        background: rgba(255, 255, 255, 0.5);
		font-size: 1em; 
        &:focus {
            border-color: #e7d1e7;
            outline: 0;
            .box-shadow(inset 0 0 1px fade(@color-nb_bg, 0%), 0 0 3px fade(@color-nb_bg, 20%);
            );
        }
    }
	
    .btn-group-lg>.btn,
    .btn-lg {
        font-size: 1em;
    }

    & .google-map {
        margin-top: 20px;
    }
	
	address { 
		p {
			margin: auto auto 0.5em;
			padding-top: 5px;
			font-size: 0.8em;
		}
	}

	.content .row {
		//margin-left: 5%;
		//margin-right: 5%;
	}
	
	i {
		top: 5px;
		//padding-left: 10px;
		padding-right: 10px;
	}
	
	.flexiContactForm label.error {
		font-family: @font-family_footer;
		font-weight: lighter;
		color: red;
		font-size: 0.6em;
	}
}