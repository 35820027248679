/////////////////////////////
// Navigation bar /////////
/////////////////////////////
// Top panel
/////////////////////////////

.top-panel {
  position: relative;
  display: block;
  height: @size-top_panel;
  font-size: 10pt;
  background-color: fade(@color-top_panel, 100%);
}

.top-panel .container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 -50% 0 0;
  transform: translate(-50%, -50%);
}

.top-panel-phone {
  float: right;
  color: @color-phone;
}

.top-panel_facebook {
  margin-left: 12pt;
  .top-panel-phone;
}

.top-panel-login {
  margin-left: 12pt;
  .top-panel-phone;
}

.top-panel i {
  margin-right: 4px;
  color: @color-icons;
}

.top-panel a {
  color: @color-phone;
}

.top-panel a:hover {
  color: @color-bg;
  text-decoration: none;
}

.top-panel-logout {
  margin-left: 30px;
}

.top-panel-fblogin {
  padding-right: 30px;
}

// Nav bar
/////////////////////////////
#layout-header {
  height: @size-header;
}

// Reset
.navbar {
  min-height: 0;
  margin-bottom: 0;
  background-color: fade(@color-nb_bg, 100%);
  border: 0;
  border-radius: 0;
}

.navbar a {
  font-weight: 500;
}

.navbar-default {
  padding-top: 0;
  padding-bottom: 0;
  font-family: @font-family-navbar;
  background-color: transparent;
  border-color: #e7e7e7;
}

.navbar-default .navbar-toggle {
  border-color: #b967ba;
}

.navbar-toggle {
  padding: 19px 23px;
}

.topbar-fixed-top {
  position: fixed;
  top: 0;
}

.navbar-default .navbar-nav > li > a {
  color: @color-nb-text_item;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: @color-nb-active_text;
  background-color: @color-nb_active_item;
}

.navbar-default .navbar-nav > li > a:hover {
  color: @color-nb-text_hover;
  background-color: @color-nb-active_bg;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
  margin-left: 15px;
}

.navbar-nav > li > a {
  padding: (@size-navbar / 2) 15px;
  line-height: 0;
}

header#layout-header .navbar-brand {
  padding-left: 70px;
  margin-top: 10px;
  background-image: url('../images/ananda.png');
  background-repeat: no-repeat;
  background-position: 4px 55%;
  background-size: auto 100%;
}

.navbar-header {
  z-index: 100;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #b967ba;
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  max-height: none;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: rgba(220, 179, 221, 0.17);
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: transparent;
}
