/* josefin-sans-100 - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-300 - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-regular - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-regular.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-600 - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-700 - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-100italic - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-100italic.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-300italic - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-300italic.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-italic - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-italic.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-600italic - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-600italic.svg#JosefinSans') format('svg'); /* Legacy iOS */
}

/* josefin-sans-700italic - latin_latin-ext */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/JosefinSans/josefin-sans-v15-latin_latin-ext-700italic.svg#JosefinSans') format('svg'); /* Legacy iOS */
}