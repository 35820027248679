//
// Maintenance layout
//


.maintenance-layout {
    body {
        background: none;
        background-color: @color-nb_bg;
    }

    h1 {
        color: rgb(255, 227, 250);
    }

    p {
        color: rgb(255, 255, 255);
    }

    a {
        color: rgb(255, 169, 251);
    }
}

