//
// All pages media queries
//
//
/* Small devices (tablets, 768px and up) */
//

@media (min-width: @screen-sm-min)
{
    .kontakt {	
        .content {
            margin-top: @size-header;
            margin-bottom: @size-header;
        }
    }
}
