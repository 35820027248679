//
// Pages
//
// Cjenik styles
//

.cjenik {
  position: relative;
  //min-height: 100%;

  .content {
    margin-top: @size-header;
    padding-bottom: @size-header;
  }

  table {
    font-size: 0.8em;
  }

  small {
    font-size: 0.8em;
  }

  .table {
    & > tbody > tr > th {
      background-color: @color-theader;
      color: @color-theader_text;
      font-family: @font-family-headings;
      font-size: 1.1em;
      font-weight: 400;
      letter-spacing: 0.008em;
      vertical-align: middle;
    }
  }

  .num {
    text-align: right;
  }

  th.num {
    width: 20%;
    border-top-right-radius: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
  }

  .footnote {
    background-color: @color-tfooter;
  }
}
