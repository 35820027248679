//
// All pages media queries
//
//

/* Large devices (large desktops, 1200px and up) */
//

@size-lg: 1.18;

@media (min-width: @screen-lg-min) {
  body::before {
    content: 'Large devices';
  }

  .setPageSizes(@size-lg);
}
