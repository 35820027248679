//
// Fonts file
//
// Allocated area for font definitions used by this application
//
@import "fonts/josefinsans.less";
//@import "fonts/montserrat.less";
@import "fonts/ptsansnarrow.less";
@import "fonts/opensans.less";
@import "fonts/playfairdisplay.less";
@import "fonts/raleway.less";
@import "fonts/glyphter.less";

//
// Chrome exhibits strange behavior when custom fonts are used
// on select inputs. This fixes the garbled text.
//
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select {
        font-family: sans-serif; 
    }
}