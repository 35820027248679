//
// Pocetna media queries
//
//

/* XXtra large devices (very large desktops, 1920px and up) */
//

@media (min-width: @screen-xxlg) {
  //
  // Nav
  #large-anchor-nav .anchor-buttons {
    h1 {
      margin-top: 0.3em;
      margin-bottom: 0.15em;
      font-size: (@font-size-h2 * 1.1);
    }

    p {
      width: 70%;
      font-size: 1em;
    }

    & > div {
      height: 100%;
    }
    .set-button(@radius-round_button-vlg, @iconsize-round_button-vlg, @iconpadding-round_button-vlg);
  }
}
