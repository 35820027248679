//
// Pages
//
// Raspored styles
//
@color-dayname: #ece;
@height-raspored-box: 290px;
@margin-bottom-raspored-box: 25px;

.raspored {

  .content {
    padding-top: @margin-bottom-raspored-box;
  }

  .content .row {
    margin-left: 5%;
    margin-right: 5%;
  }

  .content-bg-large {
    margin-top: 0;
    padding-bottom: 0;
  }

  .content-bg-large > section {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0;
    padding-top: 0;
  }

  .box {
    display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
	//text-align: center;
    //width: 100%;
    border: 6px solid lighten(@color-nb_bg, 5%);
    .border-left-radius(15px);
    .border-right-radius(15px);
    height: @height-raspored-box;
    //background-color: fade(@color-top_panel, 95%);
    #gradient > .radial(fade(@color-top_panel, 90%); darken(@color-top_panel, 5%));
    margin-bottom: @margin-bottom-raspored-box;
	

    h6 {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 600;
      position: relative;
      top: 15px;
      color: @color-dayname;
      font-size: 1.2em;
    }

    p {
      color: lighten(@color-nb_bg, 50%);
      text-align: center;
      font-size: 0.7em;
    }

    p + p {
      .reset-p;
    }

    p.time {
      font-size: 0.7em;
      font-weight: 600;
      font-family: "Open Sans",sans-serif;
      margin-bottom: 0;
      line-height: 1;
      text-align: center;
      color: lighten(@color-top_panel, 65%);
    }

    hr {
      margin: 8px 16px 12px;
      border-top: 1px solid lighten(@color-top_panel, 50%);
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      height: 0;
    }

    .info {
	  margin: auto;
      width: 90%;
    }

    .legend {
      margin-top: 30px;
	  margin: auto;
      width: 90%;
    }

    .legend p {
      font-size: 0.55em;
      .reset-p;
    }
  }
}
