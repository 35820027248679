//
// Pages
//
// Pocetna styles
//

.pocetna {
  position: relative;
  min-height: 100%;

  p~img {
    padding-bottom: 0;
  }

 .content-bg-large {
    margin-top: -(@size-header + (@size-header / 2));
    padding-bottom: 100px;
  }

  .content-bg-large > section {
    margin-top: (@size-header / 2);
    padding-top: (@size-header / 2);
  }
  
  .content-bg-small {
    position: relative;
    z-index: 0;
  }
  
  .section-content img {
    margin: 0 10px 0 0;
  }

  section div:last-of-type {
    //margin-bottom: -15px;
  }

  #teacher img {
    margin-top: 40px;
  }

  h3 span {
    display: block;
    margin-top: 9px;
    clear: left;
    font-size: 0.8em;
  }
}

// Large anchor navigation
/////////////////////////////

.set-button(@radius, @iconsize, @iconpadding) {
  .set-icon(@iconsize, @iconpadding);

  .btn-circle {
    width: @radius;
    height: @radius;
    text-align: center;
    background-color: @color-round_button;
    border: 0;
    border-radius: 50%;
  }

  .btn-circle i {
    position: relative;
    top: 12px;
  }
}

#large-anchor-nav.anchor-nav {
  z-index: 2;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

#large-anchor-nav h1 {
  z-index: 2000;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0.3em;
  margin-bottom: 0.2em;
  font-size: (@font-size-h1 / 1.7);
  text-align: center;
}

#large-anchor-nav .anchor-buttons {
  position: relative;
  top: ((-1 * @size-header) / 3);

  &>div {
    height: 22vh;
  }

  p {
    display: inline-block;
    padding: 0;
    margin-bottom: 10px;
    font-size: 0.8em;
    font-style: italic;
    line-height: 1.45;
    text-align: center;
  }

  a {
    display: block;
    text-align: center;
    text-decoration: none;
    outline: 0;
  }

  .btn.btn-default.btn-lg {
    background-color: @color-round_button;
  }

  .set-button(@radius-round_button, @iconsize-round_button, @iconpadding-round_button);
}

// Sections
/////////////////////////////

.section {
  //padding-top: 20px;
  clear: left;
}

.img-thumbnail-round {
  .img-thumbnail;
  display: block;
  margin-top: 20px;
  margin-bottom: 0;
  border-radius: 50%;
}

// Small anchor navigation
/////////////////////////////

#small-anchor-nav {
  position: fixed;
  top: 50%;
  left: 100%;
  z-index: 2;
  display: none;
  margin-left: -60px;

  ol,
  ul {
    list-style: none;
  }

  ul {
    padding: 0;
    margin: 0;
    line-height: 0;
  }

  ul li {
    display: block;
    width: 32px;
    height: 32px;
    font-size: 1.1em;
    font-style: normal;
    line-height: 0;
  }

  ul li a {
    display: block;
    padding: 10px;
  }

  ul li a span {
    position: relative;
    top: 50%;
    left: 0;
    z-index: 1;
    display: block;
    width: 7px;
    height: 7px;
    background: @color-round_button;
    border-radius: 50%;
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
    transform-origin: center;
  }

  .active {
    transform: scale(1.5);
  }

  .anchor-label {
    position: relative;
    top: -4px;
    left: -230px;
    display: block;
    width: 220px;
    max-width: 220px;
    font-family: arial, helvetica, sans-serif;
    font-size: 10px;
    color: #333;
    text-align: right;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
  }
}

//
// Sections backgrounds

.setBg(@url) {
  position: relative;
  width: 100%;
  height: auto;
  background: url(@url) no-repeat center center fixed;
  background-position: center;
  background-size: cover;
}

.bg01 {
  z-index: -2;
  @url: '../images/usluge/bg/bg01.jpg';
  .setBg(@url);
}

.bg-thai {
  z-index: -2;
  @url: '../images/usluge/bg/bg02.jpg';
  .setBg(@url);
}

.bg-yoga {
  margin-top: 47.5px;
  z-index: -2;
  @url: '../images/usluge/bg/bg03.jpg';
  .setBg(@url);
}

.bg04 {
  z-index: -2;
  @url: '../images/usluge/bg/bg04.jpg';
  .setBg(@url);
}

.bg-teacher {
  z-index: -2;
  @url: '../images/usluge/bg/bg05.jpg';
  .setBg(@url);
}

.overflow-screen {}

.full-screen {
  height: 100%;
}

.content-bg-small::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: '';
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 3px 0 rgba(115, 115, 115, 1);
  opacity: 0.9;
  -moz-box-shadow: 0 0 3px 0 rgba(115, 115, 115, 1);
  -webkit-box-shadow: 0 0 3px 0 rgba(115, 115, 115, 1);
}



.accordion {
  margin-bottom: @margin-image-thumbnail;
}

.pocetna article h3+section .accordion {
  margin-top: 25px;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.3;
}

.panel-group .panel {
  margin-bottom: 0;
  line-height: 2;
  border-radius: 4px;
}

.panel {
  opacity: 0.8;
}

.panel ul {
  font-size: 1em;
}

.panel ul li {
  font-size: 1em;
  line-height: 2;
  font-style: normal;
}

.panel a,
.panel a:active,
a:focus {
  outline: none;
  /* Works in Firefox, Chrome, IE8 and above */
}

.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
  display: block;
  width: 100%;
  color: inherit;
  opacity: 0.9;
}

.collapse-arrow {
  float: right;
}

.gg-yinyang {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: rotate(95deg) scale(var(--ggs,1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 22px
 }
 
 .gg-yinyang::after,
 .gg-yinyang::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  top: 4px
 }
 
 .gg-yinyang::before {
  border: 2px solid;
  left: 0
 }
 
 .gg-yinyang::after {
  border: 2px solid transparent;
  right: 0;
  box-shadow:
  inset 0 0 0 4px,
  0 -3px 0 1px,
  -2px -4px 0 1px,
  -8px -5px 0 -1px,
  -11px -3px 0 -2px,
  -12px -1px 0 -3px,
  -6px -6px 0 -1px
 } 