//
// Variables file
//
// Space for any custom variables used by this application
//

// Icons
// --------------------------------------------------
@FontAwesomePath:            "../vendor/font-awesome/font";

// Typography
// --------------------------------------------------

// Body text
@font-family-base:              "Raleway", sans-serif;
@font-family-sans-serif:        @font-family-base;

// Headings
@font-family-headings:          "Playfair Display", serif;

// Footer
@font-family_footer:            "Open Sans", sans-serif;

@font-family-navbar:            "PT Sans Narrow", sans-serif;
@font-size-base:                18px;
@line-height:                   (@line-height-base * 1.27);

//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
@icon-font-path:                "../fonts/Glyphicons/";
//** File name for all font files.
@icon-font-name:                "glyphicons-halflings-regular";
//** Element ID within SVG icon file.
@icon-font-svg-id:              "glyphicons_halflings-regular";

@font-size-h1:                  floor((@font-size-base * 2.2));
@font-size-h2:                  floor((@font-size-base * 2.1));
@font-size-h3:                  ceil((@font-size-base * 1.6));
@font-size-h4:                  ceil((@font-size-base * 1.4));
@font-size-h5:                  @font-size-base;
@font-size-h6:                  ceil((@font-size-base * 0.85));

// Grid
@grid-columns:                  12;
//@grid-gutter-width:           20px;
// Large screen / wide desktop
//@container-large-desktop:     (940px + @grid-gutter-width);

// Callouts
// --------------------------------------------------
@callout-padding:               20px;
@callout-border-radius:         @border-radius-base;
@callout-border:                @gray-lighter;

@callout-info-bg:               #f4f8fa;
@callout-info-text:             @state-info-text;
@callout-info-border:           @state-info-border;

@callout-warning-bg:            #faf8f0;
@callout-warning-text:          @state-warning-text;
@callout-warning-border:        @state-warning-border;

@callout-danger-bg:             #fdf7f7;
@callout-danger-text:           @state-danger-text;
@callout-danger-border:         @state-danger-border;

@callout-success-bg:            #f9fdf7;
@callout-success-text:          @state-success-text;
@callout-success-border:        @state-success-border;

// Basic elements properties                          //
// -------------------------------------------------- //

// Top panel
@color-top_panel:              	#400f5b;
@size-top_panel:                25px;
@color-icons:                   #fff;
@color-phone:                   #fad8fb;

// Navigation bar
@color-nb_bg:                   #8A018C;
@color-nb-text_item:            desaturate(lighten(@color-nb_bg, 35%), 20%);
@color-nb-text_hover:           lighten(@color-nb_bg, 65%);

@color-nb-active_bg:            lighten(@color-nb_bg, 10%);
@color-nb_active_item:          lighten(@color-nb_bg, 6%);
@color-nb-active_text:          lighten(@color-nb_bg, 70%);

@size-navbar:                   70px;

// Body
@color-bg:                      #ffeeff;
@color-headings:                #225db5;
@color-headings_acc:            @color-nb_bg;

// Header
@size-header:                   (@size-top_panel+@size-navbar);

// Content
@padding-master_top:            36px;

// Body links
@color-links:                   @color-nb_bg;
@color-links_hover:             darken(@color-links, 10%);

// Footer
@size-footer-sm:                 594px;
@size-footer-md:                 367px;
@size-footer-lg:                 501px;
@size-footer:                   @size-footer-sm;

// Round buttons
// ---------------------------------------------------

// Colors
@color-paragraph:               #222;
@color-round_button:            darken(@color-nb_bg, 5%);
@color-round_button_border:     @color-top_panel;
@color-round_button_hover:      @color-top_panel;

// Radius
@radius-round_button:           4.3em;
@radius-round_button-sm:        5em;
@radius-round_button-md:        7em;
@radius-round_button-lg:        8em;
@radius-round_button-vlg:       10em;

// Icons
@iconsize-round_button:         2.2em;
@iconsize-round_button-sm:      2.6em;
@iconsize-round_button-md:      3.6em;
@iconsize-round_button-lg:      3.2em;
@iconsize-round_button-vlg:     4.4em;

@iconpadding-round_button:      0.5em;
@iconpadding-round_button-sm:   0.6em;
@iconpadding-round_button-md:   1em;
@iconpadding-round_button-lg:	1.6em;
@iconpadding-round_button-vlg:	1.9em;
@iconcolor-round_button:        #fff;

// Tables
// ----------------------------------------------------
// Cjenik table
@color-theader:                 fade(@color-top_panel, 90%);
@color-tfooter:	                fade(@color-nb_active_item, 5%);
@color-theader_text:            #fff;

// Buttons
// ----------------------------------------------------
@btn-primary-color:             #fff;
@btn-primary-bg:                @color-nb_bg;
@btn-primary-border:            darken(@color-nb_bg, 5%);
@btn-font-size:                 @font-size-base;

// Margins
// ----------------------------------------------------
@margin-top:                    (@size-header + @padding-master_top);
@margin-bottom:                 (@size-footer + @padding-master_top);
@margin-top-h2:                 80px;
@margin-bottom-h2:              25px;
@margin-top-h3:                 90px;
@margin-bottom-h3:              20px;
@margin-image-thumbnail:        30px;
@margin-bottom-raspored-box:    25px;

// Paddings
// ----------------------------------------------------
@padding-top-h2:                20px;
@padding-bottom-h2:             @padding-top-h2;
@padding-top-article:           60px;
@padding-bottom-article:        20px;
@padding-top-hr:                20px;
@padding-bottom-hr:             20px;

// Container sizes
@container-xlg:	                (1582px + @grid-gutter-width);
@container-xxlg:                (1890px + @grid-gutter-width);

// Media queries breakpoints
// ----------------------------------------------------
@screen-xlg:                    1612px;
@screen-xxlg:                   1920px;
